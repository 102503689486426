import { Box, Container, Grid, Link } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import PaymentFooter from "./components/PaymentFooter";

import "./index.scss";
function Footer() {
  const navigate = useNavigate();
  const mobileWidth = window.innerWidth;
  //asd
  return (
    <div className="footer-container">
      <Box className="footer-inner-container">
        <Container maxWidth="xl">
          <Grid container>
            {/* <LegalFooter /> */}
            <PaymentFooter />
            <Grid item className="grid-inner-container">
              <p>
                <strong className="footer-strong">
                  IMPORTANT RISK INFORMATION
                </strong>
                <br></br>
                Trading CFDs involves significant risk due to leverage, and
                there is a possibility of losing all your invested capital.
                These products may not be suitable for every investor of Great
                North Markets. Do not risk more than you are willing to lose.
                Ensure you fully understand the associated risks, consider your
                trading experience, and seek professional advice if necessary
                before making any decisions. We disclaim any liability for
                losses or damages, including indirect or consequential, that may
                arise from CFD trading.
              </p>
            </Grid>
          </Grid>
          <Box className="footer-container-box">
            <Box onClick={() => navigate("/privacy")}>
              <Link className="footer-container-link">Privacy Policy</Link>
            </Box>
            <Box onClick={() => navigate("/terms")}>
              <Link className="footer-container-link">Terms & Conditions</Link>
            </Box>
            <Box onClick={() => navigate("/aml")}>
              <Link className="footer-container-link">AML Policy</Link>
            </Box>
            <Box onClick={() => navigate("/deposit-policy")}>
              <Link className="footer-container-link">Deposit Policy</Link>
            </Box>
            <Box onClick={() => navigate("/refund-policy")}>
              <Link className="footer-container-link">Refund Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security-policy")}>
              <Link className="footer-container-link">Security Policy</Link>
            </Box>
            <Box onClick={() => navigate("/risk-disclaimer")}>
              <Link className="footer-container-link">Risk Disclaimer</Link>
            </Box>
            <Box onClick={() => navigate("/kyc")}>
              <Link className="footer-container-link">KYC Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security")}>
              <Link className="footer-container-link">Security</Link>
            </Box>
            <Box onClick={() => navigate("/mobile-privacy")}>
              <Link className="footer-container-link">Mobile Privacy</Link>
            </Box>
          </Box>
          <Box className="location-box-container">
            <span>
              © 2023 Great North Markets | Hardstrasse 201, 8005 Zürich,
              Switzerland Prime Tower, address
            </span>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default Footer;
